import React from "react"
import styled from 'styled-components'

import { graphql, Link } from 'gatsby'
import Layout from '../../components/layout'

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <div className="blog-post-container">
        <div className="blog-post">
          <h1>{frontmatter.title}</h1>
          <h2>Written on: {frontmatter.date}</h2>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </div>
      <Link to="/blog">Back to Blog</Link>
      <StyledLink to="#top">Scroll to top</StyledLink>
    </Layout>
  )
}

const StyledLink = styled(Link)`
  display: block;
  margin-top: 1rem;
`

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
